<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-alert
        type="info"
        color="green darken-2"
        :value="true"
        class="mb-4"
        outline
      >
        Please tick the checkbox on the header to export all numbers or tick preferred number individually.
      </v-alert>

      <v-btn
        :disabled="!load"
        block
        color="primary"
        @click="getReservedMsisdn"
      >
        Load 100 number from pool
      </v-btn>
      <v-toolbar flat>
        <v-toolbar-title>Request</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        />
        <v-spacer />
        <input
          type="checkbox"
          v-model="selectAll"
        >
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :pagination.sync="pagination"
        :rows-per-page-items="[10,25,50,100]"
        class="elevation-1"
        item-key="msisdn"
      >
        <template #items="props">
          <tr>
            <td class="text-xs-left">
              {{ props.item.msisdn }}
            </td>
            <td class="text-xs-right">
              <input
                type="checkbox"
                v-model="checked"
                :value="props.item.msisdn"
                @input="props.item.msisdn= $event.target.value"
              >
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-dialog
        v-model="executeDialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-on="on"
          >
            Execute Reserved Number
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Execute  {{ checked.length }} numbers.</span>
          </v-card-title>

          <v-alert
            :value="true"
            :type="executeAlertType"
            v-html="executeAlertMessage"
          />

          <v-card-actions v-if="show == true">
            <v-card-text>
              The purpose to reserved those number is for
            </v-card-text>
            &nbsp;
            <v-flex xs7>
              <v-select
                v-model="select"
                :items="selects"
                item-text="text"
                item-value="value"
                label="Select purpose"
              />
            </v-flex>
          </v-card-actions>

          <v-card-actions v-if="pdf== true">
            <v-card-text>
              Click Export to excel to download
            </v-card-text>
            &nbsp;
            <v-btn
              dark
              @click="exportReport()"
            >
              Export to excel
            </v-btn>
          </v-card-actions>

          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="loading"
              color="blue darken-1"
              @click="executeDialog = false"
            >
              {{ executeAlertType === 'info' ? 'Cancel' : 'Close' }}
            </v-btn>
            <v-btn
              v-show="executeAlertType === 'info'"
              :loading="loading"
              :disabled="loading"
              color="blue darken-1"
              @click="executeBookedMsisdn"
            >
              Execute
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar"
        color="success"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      <v-snackbar
        v-model="snackbarError"
        color="error"
        :timeout="5000"
      >
        {{ snackbarText }}
        <v-btn
          dark
          flat
          @click="snackbarError = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'
import zipcelx from 'zipcelx'
import { DateTime } from 'luxon'

const pageTitle = 'Reserved Number'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      title: pageTitle,
      pagination: {
        descending: true,
        sortBy: 'icon',
      },
      select: null,
      selects: [
        {
          text: 'BR',
          value: 'BR',
        },
        {
          text: 'JDT',
          value: 'JDT',
        },
      ],
      reservedMsisdns: {
        msisdn: null,
      },
      load: true,
      show: true,
      pdf: false,
      reserved: [],
      items: [],
      checked: [],
      today: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL-dd'),
      loading: false,
      dialog: false,
      executeDialog: false,
      executeAlertMessage: null,
      executeAlertType: 'info',
      breadcrumbs: [
        {
          text: 'Operation Kits', disabled: false, to: '/operation/operation_kits',
        },
        {
          text: 'Corporate Kits', disabled: false, to: '/operation/corporate_kits',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      enableExport: true,
      exportedReport: [],
      exportReportHeader:
          [
            {
              value: 'Msisdn',
              type: 'string',
            },
            {
              value: 'Dealer',
              type: 'string',
            },
            {
              value: 'Token',
              type: 'string',
            },
            {
              value: 'Reservation',
              type: 'string',
            },
          ],
      headers: [
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Mark',
          align: 'right',
          sortable: true,
          value: 'msisdn',
        },
      ],
      formTitle: 'Reserved Number',
      alert: false,
      alertMessage: null,
      snackbar: false,
      snackbarError: false,
      snackbarText: null,
    }
  },

  computed: {
    selectAll: {
      get: function () {
        return this.items ? this.checked.length === this.items.length : false
      },
      set: function (value) {
        var checked = []

        if (value) {
          this.items.forEach(function (item) {
            checked.push(item.msisdn)
          })
        }
        this.checked = checked
      },
    },
  },

  watch: {
    executeDialog (val) {
      if (val) {
        this.executeAlertType = 'info'
        this.executeAlertMessage = '<div>Please select the purpose and click <strong>EXECUTE</strong> only when you have select the purpose and numbers. </div>'
      }
    },
    exportedReport: function () {
      const config = {
        filename: 'ReservedNumber' + '-' + this.today,
        sheet: {
          data: [],
        },
      }
      config.sheet.data.push(this.exportReportHeader)
      this.exportedReport.forEach(item => {
        const transaction = [
          {
            value: item.msisdn,
            type: 'string',
          },
          {
            value: item.dealer,
            type: 'string',
          },
          {
            value: item.token,
            type: 'string',
          },
          {
            value: item.reservation,
            type: 'string',
          },
        ]
        config.sheet.data.push(transaction)
      })
      zipcelx(config)
      this.enableExport = true
    },
  },

  methods: {
    getReservedMsisdn () {
      const params = createGetParams({})
      this.loading = true
      this.load = true
      this.$rest.get('getReservedMsisdn.php', params)
        .then((response) => {
          this.items = response.data.reserved
          this.executeReservedMsisdn()
          this.loading = false
          this.load = false
        }).catch(error => {
          console.log(error)
          this.loading = false
          this.load = false
        })
    },

    async executeReservedMsisdn () {
      const params = createPostParams({
        action: 'reservedMsisdn',
      })

      try {
        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage = 'Your request to load numbers was successful.'
        })
      } catch (e) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = e.message + ': ' + e.response.data
        })
      } finally {
        this.loading = false
      }
    },

    async executeBookedMsisdn () {
      const params = createPostParams({
        action: 'bookedMsisdn',
        token: this.select,
        id: this.checked.length,
        payload: {
          booked: this.checked,
        },
      })

      try {
        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage = 'Your request to reserved msisdns was successful.'
          this.show = false
          this.pdf = true
        })
      } catch (e) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = e.message + ': ' + e.response.data
          this.show = false
        })
      } finally {
        this.loading = false
      }
    },

    async getBookedMsisdn (report = false) {
      this.loading = true
      const params = createGetParams({})
      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(params, { cancelToken: this.source.token })
        const response = await this.$rest.get('getReservedDocument.php', params)
        this.error = null
        this.loading = false
        return response.data
      } catch (error) {
        if (!this.$rest.isCancel(error)) {
          this.error = error.message
          this.loading = false
        }
      }
    },

    exportReport: function () {
      this.enableExport = false
      this.getBookedMsisdn().then(data => {
        this.exportedReport = data.reserved
      })
    },

    showError (message) {
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>

<style scoped>
input[type=checkbox] {
    transform: scale(1.5);
}

</style>
